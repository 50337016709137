export const bookmakers = {
  BETONLINE: 'BETONLINE',
  BETMGM: 'BETMGM',
  BETRIVERS: 'BETRIVERS',
  BOVADA: 'BOVADA',
  CAESARS: 'CAESARS',
  DRAFTKINGS: 'DRAFTKINGS',
  FANDUEL: 'FANDUEL',
  GAMBET: 'GAMBET',
  PRIZEPICKS: 'PRIZEPICKS',
  PINNACLE: 'PINNACLE'
}

export const time_type = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE'
}

export const selection_type = {
  OVER: 'OVER',
  UNDER: 'UNDER',
  YES: 'YES',
  NO: 'NO'
}

export const team_game_market_types = {
  GAME_SPREAD: 'GAME_SPREAD',
  GAME_TOTAL: 'GAME_TOTAL',
  GAME_MONEYLINE: 'GAME_MONEYLINE'
}

export const player_season_prop_types = {
  SEASON_PASSING_YARDS: 'SEASON_PASSING_YARDS',
  SEASON_PASSING_TOUCHDOWNS: 'SEASON_PASSING_TOUCHDOWNS',
  SEASON_PASSING_COMPLETIONS: 'SEASON_PASSING_COMPLETIONS',
  SEASON_RUSHING_YARDS: 'SEASON_RUSHING_YARDS',
  SEASON_RUSHING_TOUCHDOWNS: 'SEASON_RUSHING_TOUCHDOWNS',
  SEASON_RECEIVING_YARDS: 'SEASON_RECEIVING_YARDS',
  SEASON_RECEIVING_TOUCHDOWNS: 'SEASON_RECEIVING_TOUCHDOWNS',
  SEASON_RECEPTIONS: 'SEASON_RECEPTIONS',
  SEASON_DEFENSE_SACKS: 'SEASON_DEFENSE_SACKS'
}

export const player_game_alt_prop_types = {
  GAME_ALT_PASSING_TOUCHDOWNS: 'GAME_ALT_PASSING_TOUCHDOWNS',
  GAME_ALT_PASSING_COMPLETIONS: 'GAME_ALT_PASSING_COMPLETIONS',
  GAME_ALT_PASSING_YARDS: 'GAME_ALT_PASSING_YARDS',
  GAME_ALT_PASSING_LONGEST_COMPLETION: 'GAME_ALT_PASSING_LONGEST_COMPLETION',
  GAME_ALT_PASSING_INTERCEPTIONS: 'GAME_ALT_PASSING_INTERCEPTIONS',
  GAME_ALT_PASSING_ATTEMPTS: 'GAME_ALT_PASSING_ATTEMPTS',

  GAME_ALT_RUSHING_YARDS: 'GAME_ALT_RUSHING_YARDS',
  GAME_ALT_RUSHING_ATTEMPTS: 'GAME_ALT_RUSHING_ATTEMPTS',
  GAME_ALT_RUSHING_TOUCHDOWNS: 'GAME_ALT_RUSHING_TOUCHDOWNS',
  GAME_ALT_LONGEST_RUSH: 'GAME_ALT_LONGEST_RUSH',

  GAME_ALT_RECEIVING_YARDS: 'GAME_ALT_RECEIVING_YARDS',
  GAME_ALT_RECEPTIONS: 'GAME_ALT_RECEPTIONS',
  GAME_ALT_RECEIVING_TOUCHDOWNS: 'GAME_ALT_RECEIVING_TOUCHDOWNS',
  GAME_ALT_LONGEST_RECEPTION: 'GAME_ALT_LONGEST_RECEPTION',

  GAME_ALT_RUSHING_RECEIVING_YARDS: 'GAME_ALT_RUSHING_RECEIVING_YARDS',

  GAME_ALT_TACKLES_ASSISTS: 'GAME_ALT_TACKLES_ASSISTS',

  GAME_ALT_DEFENSE_INTERCEPTIONS: 'GAME_ALT_DEFENSE_INTERCEPTIONS',
  GAME_ALT_DEFENSE_SACKS: 'GAME_ALT_DEFENSE_SACKS'
}

export const player_game_prop_types = {
  GAME_PASSING_YARDS: 'GAME_PASSING_YARDS',
  GAME_RECEIVING_YARDS: 'GAME_RECEIVING_YARDS',
  GAME_RUSHING_YARDS: 'GAME_RUSHING_YARDS',
  GAME_PASSING_COMPLETIONS: 'GAME_PASSING_COMPLETIONS',
  GAME_PASSING_TOUCHDOWNS: 'GAME_PASSING_TOUCHDOWNS',
  GAME_PASSING_COMPLETION_PERCENTAGE: 'GAME_PASSING_COMPLETION_PERCENTAGE',
  GAME_RECEPTIONS: 'GAME_RECEPTIONS',
  GAME_PASSING_INTERCEPTIONS: 'GAME_PASSING_INTERCEPTIONS',
  GAME_RUSHING_ATTEMPTS: 'GAME_RUSHING_ATTEMPTS',
  GAME_RUSHING_RECEIVING_YARDS: 'GAME_RUSHING_RECEIVING_YARDS',
  GAME_RECEIVING_TOUCHDOWNS: 'GAME_RECEIVING_TOUCHDOWNS',
  GAME_RECEIVING_TARGETS: 'GAME_RECEIVING_TARGETS',
  GAME_RUSHING_TOUCHDOWNS: 'GAME_RUSHING_TOUCHDOWNS',
  GAME_PASSING_ATTEMPTS: 'GAME_PASSING_ATTEMPTS',
  GAME_PASSING_LONGEST_COMPLETION: 'GAME_PASSING_LONGEST_COMPLETION',
  GAME_LONGEST_RECEPTION: 'GAME_LONGEST_RECEPTION',
  GAME_RUSHING_RECEIVING_TOUCHDOWNS: 'GAME_RUSHING_RECEIVING_TOUCHDOWNS',
  GAME_LONGEST_RUSH: 'GAME_LONGEST_RUSH',
  GAME_TACKLES_ASSISTS: 'GAME_TACKLES_ASSISTS',
  GAME_PASSING_RUSHING_YARDS: 'GAME_PASSING_RUSHING_YARDS',
  GAME_DEFENSE_SACKS: 'GAME_DEFENSE_SACKS',
  GAME_TACKLES_FOR_LOSS: 'GAME_TACKLES_FOR_LOSS',
  GAME_PUNTS: 'GAME_PUNTS',

  GAME_FIELD_GOALS_MADE: 'GAME_FIELD_GOALS_MADE',

  GAME_FIRST_TOUCHDOWN_SCORER: 'GAME_FIRST_TOUCHDOWN_SCORER',
  GAME_LAST_TOUCHDOWN_SCORER: 'GAME_LAST_TOUCHDOWN_SCORER',

  ...player_game_alt_prop_types,

  GAME_PASS_DEFLECTIONS: 'GAME_PASS_DEFLECTIONS',
  GAME_PASSING_RUSHING_RECEIVING_TOUCHDOWNS:
    'GAME_PASSING_RUSHING_RECEIVING_TOUCHDOWNS',

  GAME_PPR_FANTASY_POINTS: 'GAME_PPR_FANTASY_POINTS'
}

export const player_prop_types = {
  ...player_season_prop_types,

  PLAYOFF_PASSING_YARDS: 'PLAYOFF_PASSING_YARDS',
  PLAYOFF_PASSING_TOUCHDOWNS: 'PLAYOFF_PASSING_TOUCHDOWNS',
  PLAYOFF_PASSING_COMPLETIONS: 'PLAYOFF_PASSING_COMPLETIONS',
  PLAYOFF_RUSHING_YARDS: 'PLAYOFF_RUSHING_YARDS',
  PLAYOFF_RUSHING_TOUCHDOWNS: 'PLAYOFF_RUSHING_TOUCHDOWNS',
  PLAYOFF_RECEIVING_YARDS: 'PLAYOFF_RECEIVING_YARDS',
  PLAYOFF_RECEIVING_TOUCHDOWNS: 'PLAYOFF_RECEIVING_TOUCHDOWNS',
  PLAYOFF_RECEPTIONS: 'PLAYOFF_RECEPTIONS',

  SEASON_LEADER_PASSING_TOUCHDOWNS: 'SEASON_LEADER_PASSING_TOUCHDOWNS',
  SEASON_LEADER_RUSHING_TOUCHDOWNS: 'SEASON_LEADER_RUSHING_TOUCHDOWNS',
  SEASON_LEADER_RECEIVING_TOUCHDOWNS: 'SEASON_LEADER_RECEIVING_TOUCHDOWNS',
  SEASON_LEADER_SACKS: 'SEASON_LEADER_SACKS',
  SEASON_LEADER_INTERCEPTIONS: 'SEASON_LEADER_INTERCEPTIONS',
  SEASON_LEADER_PASSING_YARDS: 'SEASON_LEADER_PASSING_YARDS',
  SEASON_LEADER_RUSHING_YARDS: 'SEASON_LEADER_RUSHING_YARDS',
  SEASON_LEADER_RECEIVING_YARDS: 'SEASON_LEADER_RECEIVING_YARDS',

  ...player_game_prop_types,

  GAME_LEADER_PASSING_YARDS: 'GAME_LEADER_PASSING_YARDS',
  GAME_LEADER_RUSHING_YARDS: 'GAME_LEADER_RUSHING_YARDS',
  GAME_LEADER_RECEIVING_YARDS: 'GAME_LEADER_RECEIVING_YARDS',

  SUNDAY_LEADER_PASSING_YARDS: 'SUNDAY_LEADER_PASSING_YARDS',
  SUNDAY_LEADER_RUSHING_YARDS: 'SUNDAY_LEADER_RUSHING_YARDS',
  SUNDAY_LEADER_RECEIVING_YARDS: 'SUNDAY_LEADER_RECEIVING_YARDS'
}
